.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  color: white;
  padding: 5px 5px 5px 0px;
}

.main-title {
  margin: 0 10px 0 0;
}

.questions-card {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  transition: 1s;
  width: 365px;
  height: 400px;
  margin: 5px 5px 5px 5px;
}

.dynamic-component-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 300px;
  width: 365px;
}

.time-picker-form {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0.3px solid black;
  padding: 2px 2px 2px 2px;
  border-radius: 5px;
  box-shadow: #282c34 1px 2px 3px;
  width: 220px;
  height: 60px;
}

.time-picker-select {
  margin: 5px 5px 5px 5px;
  font-size: 30px;
}

.hours-flying-wrapper,
.minutes-flying-wrapper {
  display: flex;
  margin: 20px 0px 0px 0px;
}

.int-type-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: inherit;
}

.card-actions {
  display: flex;
  justify-content: space-between;
  height: 100px;
  width: 365px;
}

.results-card {
  width: 365px;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  padding: 5px 8px 5px 8px;
  align-items: baseline;
}

.footer {
  background-color: #282c34;
  display: flex;
  justify-content: space-between;
  padding-right: 3px;
  padding-left: 5px;
  align-items: center;
  color: white;
  text-align: center;
  margin-top: 10px;
}

a {
  text-decoration: none;
  color: #fafafa;
}

.brave-ad {
  width: 100%;
  display: flex;
  background-color: #282c34;
  flex-direction: column;
  text-align: center;
  justify-content: space-evenly;
  align-items: center;
  padding: 2px 2px 2px 2px;
  margin-top: 5px;
}

.donation-wrapper {
  margin: auto;
  width: 400px;
}

.donation-text {
  font-family: 'ZCOOL XiaoWei', serif;
  font-size: 1.5rem;
  margin: 0 -80px;
  padding: 10px;
}

.sponsor-modal-wrapper {
  background-color: rgb(68, 68, 66);
  text-align: center;
}

/* these are the titles in the sponsor modal */
h4 {
  color: white;
}

.ck {
  font-size: 1.3rem;
  background: -webkit-linear-gradient(#eee, grey);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.5px grey;
}
.ep {
  font-size: 1.2rem;
  background: -webkit-linear-gradient(#eee, Yellow);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 0.5px yellow;
}
.plat {
  font-size: 1.1rem;
  color: rgb(183, 250, 255);
}
.goldies {
  font-size: 1rem;
  line-height: 70%;
  color: rgb(242, 207, 9);
}

@media (max-width: 560px) {
  .donation-text {
    font-size: 1.2rem;
    margin: 0;
    padding: 20px;
  }
}
